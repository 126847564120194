import React, { CSSProperties } from "react";

// Customizable Area Start
import TaskDetailsController, {
  Props,
  configJSON,
} from "./TaskDetailsController.web";
import { Box, Chip, createTheme, MenuItem, Modal, Paper, Radio, Select, SelectChangeEvent, Slider, styled, TextField, ThemeProvider, Typography } from "@mui/material";
import Layout from "../../dashboard/src/Layout.web";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { arrowDown, clockIcon, closeChip, editPencilIcon, successCheck } from "./assets";
import { checkbox, checked } from "../../customform/src/assets";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { downArrowIcon } from "../../DynamicContent/src/assets";
import moment from "moment";
import Loader from "../../../components/src/Loader.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  },
});
// Customizable Area End

export default class TaskDetails extends TaskDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderHeaderView() {
    const options = this.state.isProtfolioManager ? this.statusOptionsPortfolioManager : this.statusOptions;
    return (
      <Box style={webStyle.headerBox}>
        <Typography style={webStyle.titleText}>{this.state.taskDetails?.task_name}</Typography>
        <Box style={{ display: "flex", flexDirection: 'row', gap: 10, alignItems: 'center' }}>
          {(this.state.isProtfolioManager && !this.state.isEditable) && (
            <Box
              data-test-id="edit-btn"
              style={webStyle.editBtnContainer}
              onClick={() => {
                this.setState({ isEditable: true });
              }}>
              <Typography style={webStyle.editBtnText}>Edit</Typography>
              <img src={editPencilIcon} style={{ width: '20px', height: '20px' }} />
            </Box>
          )}

          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            data-test-id="status-dropdown"
            value={options.find(option => option.value === this.state.status)?.value}
            onChange={(event: SelectChangeEvent) => {
              this.setState({ status: event.target.value }, () => {
                this.updateTaskStatus();
              });
            }}
            style={webStyle.dropdownContainer}
            variant="outlined"
            IconComponent={(props) => <img {...props} src={downArrowIcon} style={{ top: "unset" }} />}
          >
            {options.map(option => (
              <MenuItem
                value={option.value}
                style={{ fontSize: '12px', lineHeight: '18px', fontWeight: 500 }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>

        </Box>
      </Box>
    )
  }

  renderSubCategory(option: any, { selected }: any) {
    return (
      <li style={{ maxHeight: "18px", display: 'flex', alignItems: 'center', padding: 0 }}>
        <Radio
          icon={<img src={checkbox} />}
          checkedIcon={<img src={checked} />}
          style={{ marginLeft: -16 }}
          checked={selected}
        />
        <Typography style={{ ...webStyle.optionName, color: selected ? "#34373A" : "#7D7D7F", }}>{option.sub_category_name}</Typography>
      </li>
    );
  }

  renderSubCategoryAndAssignee() {
    return (
      <Box style={webStyle.container}>
        <Box style={webStyle.fieldBox}>
          <Typography style={webStyle.taskNameLabel}>Subcategory</Typography>
          <Autocomplete
            id="subcategory"
            options={this.state.subCategoriesList}
            disableCloseOnSelect={false}
            value={this.state.subCategoriesList.find(option => option.id === this.state.selectedSubCategory)}
            data-test-id="sub-category-field"
            onChange={(event, value) => this.setState({ selectedSubCategory: value?.id ?? 0 })}
            closeIcon={false}
            popupIcon={<div style={webStyle.arrowDiv}>
              <img src={arrowDown} />
            </div>}
            renderInput={(params) =>
              <TextField
                {...params}
                name="memberlist"
                placeholder="Select one from the list"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  style: (this.state.error && this.state.selectedSubCategory === 0) ? webStyle.autoErrorComplete : webStyle.autoComplete
                }}
                inputProps={{
                  ...params.inputProps,
                  value: this.state.subCategoriesList.find(option => option.id === this.state.selectedSubCategory)?.sub_category_name
                }}
              />
            }
            renderOption={this.renderSubCategory}
            PopperComponent={({ children, ...other }) => (
              <div {...other} style={webStyle.modelSubpopper}>
                {children}
              </div>
            )}
            getOptionLabel={(option: any) => option.sub_category_name}
            disabled={this.checkIsDisabled()}
          />
          {(this.state.error && this.state.selectedSubCategory === 0) &&
            <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
          }
        </Box>

        <Box style={webStyle.fieldBox}>
          <Typography style={webStyle.taskNameLabel}>Assignee</Typography>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            data-testid="assignee-field"
            value={this.state.selectedAssignee}
            options={this.state.assigneeList}
            disableCloseOnSelect
            onChange={(event, value) => {
              if (value?.length <= 3) {
                this.setState({ selectedAssignee: value })
              }
            }}
            closeIcon={false}
            popupIcon={<div style={webStyle.arrowDiv}>
              <img src={arrowDown} />
            </div>}
            renderTags={(tagValue, getTagProps) =>
              tagValue.slice(0, 3).map((option, index) => (
                <Chip
                  label={`${option.first_name} ${option.last_name}`}
                  {...getTagProps({ index })}
                  style={{
                    background: '#ECFBF1',
                    borderRadius: '8px',
                    color: '#329E54',
                    fontSize: '12px',
                    fontWeight: 500,
                    fontFamily: 'Lato',
                    padding: '10px 3px'
                  }}
                  deleteIcon={<img src={closeChip} style={{ width: '14px' }} />}
                />
              ))
            }
            PopperComponent={({ children, ...other }) => (
              <div {...other} style={webStyle.modelpopper}>
                {children}
              </div>
            )}
            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
            renderInput={(params: any) =>
              <TextField
                {...params}
                name="memberlist"
                placeholder={this.state.selectedAssignee.length === 0 ? "Select one from the list" : ""}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  style: (this.state.error && this.state.selectedAssignee.length === 0) ? webStyle.autoErrorComplete : webStyle.autoComplete,
                  type: 'search'
                }}
              />
            }
            renderOption={(option: any, { selected }: any) => {
              return (
                <li style={{ maxHeight: "18px", display: 'flex', alignItems: 'center', padding: 0 }}>
                  <Radio
                    icon={<img src={checkbox} />}
                    checkedIcon={<img src={checked} />}
                    style={{ marginLeft: -16 }}
                    checked={selected}
                  />
                  <Typography style={{ ...webStyle.optionName, color: selected ? "#34373A" : "#7D7D7F", }}>{option.first_name} {option.last_name}</Typography>
                </li>
              );
            }}
            disabled={this.checkIsDisabled()}
          />
          {(this.state.error && this.state.selectedAssignee.length === 0) &&
            <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
          }
        </Box>
      </Box>
    )
  }

  renderDateAndCriticality() {
    return (
      <Box style={webStyle.container}>
        <Box style={webStyle.fieldBox}>
          <Typography style={webStyle.taskNameLabel}>{configJSON.StartDate}</Typography>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              data-testid="start-date"
              placeholder="DD-MM-YYYY"
              InputProps={{
                disableUnderline: true,
              }}
              format="DD-MM-YYYY"
              autoOk
              openTo="date"
              style={(this.state.error && this.state.startDate === null) ? webStyle.datetimeinputError : webStyle.datetimeinput}
              fullWidth
              disablePast
              value={this.state.startDate}
              onChange={this.handleStartDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              disabled={this.checkIsDisabled()}
              minDateMessage=''
              maxDateMessage=''
            />
          </MuiPickersUtilsProvider>
          {(this.state.error && this.state.startDate === null) &&
            <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
          }
        </Box>

        <Box style={webStyle.fieldBox}>
          <Typography style={webStyle.taskNameLabel}>{configJSON.EndDate}</Typography>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              data-testid="end-date"
              placeholder="DD-MM-YYYY"
              InputProps={{
                disableUnderline: true,
              }}
              format="DD-MM-YYYY"
              autoOk
              openTo="date"
              minDate={this.state.startDate !== null ? this.state.startDate : ''}
              style={(this.state.error && this.state.endDate === null) ? webStyle.datetimeinputError : webStyle.datetimeinput}
              fullWidth
              disablePast
              value={this.state.endDate}
              onChange={this.handleChangeEndDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              minDateMessage=''
              maxDateMessage=''
              disabled={this.checkIsDisabled()}
            />
          </MuiPickersUtilsProvider>
          {(this.state.error && this.state.endDate === null) &&
            <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
          }
        </Box>

        <Box style={webStyle.fieldBox}>
          <Typography style={webStyle.taskNameLabel}>{configJSON.SelectCriticality}</Typography>
          <Autocomplete
            id="critical"
            options={this.criticalOptions}
            data-testid="criticality-field"
            onChange={(event, value) => this.setState({ criticalValue: value?.value ?? '' })}
            disableCloseOnSelect={false}
            closeIcon={false}
            popupIcon={<div style={webStyle.arrowDiv}>
              <img src={arrowDown} />
            </div>}
            PopperComponent={({ children, ...other }) => (
              <div {...other} style={webStyle.modelpopper}>
                {children}
              </div>
            )}
            getOptionLabel={(option: any) => option.label}
            renderOption={(option: any, { selected }: any) => {
              return (
                <li style={{ maxHeight: "18px", display: 'flex', alignItems: 'center', padding: 0 }}>
                  <Radio
                    icon={<img src={checkbox} />}
                    checkedIcon={<img src={checked} />}
                    style={{ marginLeft: -16 }}
                    checked={selected}
                  />
                  <Typography style={{ ...webStyle.optionName, color: selected ? "#34373A" : "#7D7D7F", }}>{option.label}</Typography>
                </li>
              );
            }}
            renderInput={(params) =>
              <TextField
                {...params}
                name="memberlist"
                placeholder="Select one from the list"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  style: (this.state.error && this.state.criticalValue === "") ? webStyle.autoErrorComplete : webStyle.autoComplete
                }}
                inputProps={{
                  ...params.inputProps,
                  value: this.criticalOptions.find(option => option.value === this.state.criticalValue)?.label
                }}
              />
            }
            value={this.criticalOptions.find(option => option.value === this.state.criticalValue)}
            disabled={this.checkIsDisabled()}
          />
          {(this.state.error && this.state.criticalValue === "") &&
            <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
          }
        </Box>
      </Box>
    )
  }

  renderActivityView() {
    return (
      <>
        <Box style={{ ...webStyle.container, marginTop: '27px', flexDirection: 'column', marginBottom: "24px" }}>
          <Typography style={webStyle.activityLabel}>Activity</Typography>

          <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '16px', alignItems: 'center' }}>
            <img src={this.state.userProfileUrl} style={{ width: "32px", height: "32px", borderRadius: "16px" }} />
            <TextField
              placeholder="Add a comment..."
              InputProps={{
                style: { border: "1px solid #D9D9D9", height: '48px', width: '100%', borderRadius: '8px' },
              }}
              style={{ display: 'flex', flexDirection: 'row', width: '100%', borderRadius: '8px' }}
              value={this.state.comment}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                if (event.target.value.length > 0) {
                  this.setState({ comment: event.target.value });
                }
              }}
              data-test-id="comment-input"
            />
            {this.state.comment && (
              <Box data-test-id="comment-btn" style={webStyle.commentBtnContainer} onClick={async () => await this.handleComment()}>
                <Typography style={webStyle.commentBtnText}>Comment</Typography>
              </Box>
            )}
          </Box>
        </Box>

        {this.state.commentsList?.length > 0 ? this.state.commentsList.map(comment => (
          <Box style={{ display: 'flex', width: '100%', gap: 16, paddingLeft: '44px', marginBottom: '30px' }}>
            <img src={comment.profile_image} style={{ width: "32px", height: "32px", borderRadius: "16px" }} />

            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography style={webStyle.commentTitleText}>{comment.user_name}</Typography>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <img src={clockIcon} style={{ width: "16px", height: "16px", marginRight: '3px' }} />
                  <Typography style={webStyle.commentMessage}>{moment(moment(comment.created_at), "YYYYMMDD").fromNow()}</Typography>
                </Box>
              </Box>
              <Typography style={webStyle.commentMessage}>{comment.comment_message}</Typography>
            </Box>
          </Box>
        )) : null}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start
    return (
      <ThemeProvider theme={theme}>
        <Layout>
          <Loader loading={this.state.isLoading} />
          {this.renderHeaderView()}
          {this.renderSubCategoryAndAssignee()}
          {this.renderDateAndCriticality()}



          <Box style={webStyle.container}>
            <Box style={webStyle.fieldBox}>
              <Typography style={webStyle.taskNameLabel}>{configJSON.Description}</Typography>
              <TaskNameField
                placeholder={configJSON.descriptionPlaceholder}
                variant="outlined"
                data-test-id="description"
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  if (event.target.value.length < 1000) {
                    this.setState({
                      description: event.target.value
                    })
                  }

                }}
                value={this.state.description}
                error={this.state.error && this.state.description === ""}
                disabled={this.checkIsDisabled()}
                multiline
                fullWidth
                rows={4}
              />
              {(this.state.error && this.state.description === "") &&
                <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
              }
            </Box>
          </Box>

          <Box style={{...webStyle.container, marginTop: '67px', justifyContent:'space-between'}}>
            <Box style={{flexDirection:'column'}}>
              <Typography style={{fontSize: '14px', lineHeight:'16.8px', fontWeight: 600, fontFamily:'Montserrat', color: '#101010',marginBottom:'3px'}}>Log your progress</Typography>
              <Typography style={{fontSize: '12px', lineHeight:'19.2px', fontWeight: 400, fontFamily:'Lato', color:'#34373A'}}>Keep track of your journey and monitor key milestones. Log your progress to stay on top of your goals!</Typography>
            </Box>
            {this.state.isEditable && (
              <Box data-test-id="save-btn" style={webStyle.saveBtnContainer} onClick={async () => await this.updateTask()}>
                <Typography style={webStyle.saveBtnText}>Save</Typography>
              </Box>
            )}
          </Box>

          <Box style={{...webStyle.container, width: '65%', marginTop: '20px',alignItems:'center'}}>
            <CustomSlider
              value={this.state.percentage}
              onChange={(event: any, value: any) => {
                this.setState({ percentage: value })
              }}
              marks={[{ value: 0 }, { value: 20 }, { value: 40 }, { value: 60 }, { value: 80 }, { value: 100 }]}
              min={0}
              max={100}
              disabled={this.checkIsDisabled()}
            />
            <Typography style={{ fontSize: '12px', lineHeight: '19.2px', fontWeight: 600, fontFamily: 'Lato', color: '#00443A' }}>
              {`${this.state.percentage}%`}
            </Typography>
          </Box>

          {this.renderActivityView()}

          <Modal
            open={this.state.completedTaskModal}
            onClose={() => this.setState({ completedTaskModal: false })}
            style={webStyle.modalWrapper}
            data-testid="complete-task-modal"
          >
            <Paper style={webStyle.completedModalPaper}>
              <img src={successCheck} />
              <Typography style={webStyle.sucsessTitle}>{configJSON.successTitle}</Typography>
              <Typography style={webStyle.successMessage}>{this.state.statusSuccessMessage}</Typography>
            </Paper>
          </Modal>

        </Layout>
      </ThemeProvider>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
}

// Customizable Area Start
const TaskNameField = styled(TextField)({
  width: '100%',
  fontSize: '14px',
  color: '#34373A',
  "& .MuiOutlinedInput-root": {
    "& textarea": {
      fontSize: '14px',
      color: '#34373A',
      background: '#FAFAFA'
    },
    "& fieldset": {
      borderColor: "#D9D9D9",
      borderWidth: '1px'
    },
    "&:hover fieldset": {
      borderColor: "#D9D9D9",
      borderWidth: '1px'
    },
    "&.Mui-focused fieldset": {
      borderColor: "#34373A",
      borderWidth: '1px'
    },
  },
});

const CustomSlider = styled(Slider)({
  height: 8,
  '& .MuiSlider-track': {
    backgroundColor: "#4CAF50",
  },
  '& .MuiSlider-rail': {
    backgroundColor: "#EBEBEB",
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: "2px solid #4CAF50",
  },
  '& .MuiSlider-mark': {
    backgroundColor: "#4CAF50",
    height: 12,
    width: 12,
    borderRadius: "50%",
  },
  '& .MuiSlider-markLabel': {
    fontSize: "0.75rem",
    color: "#757575",
  },
});

const webStyle = {
  headerBox: {
    marginTop: "32px",
    paddingLeft: "44px",
    width: "75%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media all and (max-width: 400px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 10
    }
  },
  titleText: {
    fontSize: "22px",
    lineHeight: "27.5px",
    fontWeight: 700,
    fontFamily: "Lato"
  },
  taskNameLabel: {
    fontFamily: 'Lato',
    fontSize: '15px',
    marginBottom: '8px'
  },
  fieldBox: {
    width: '100%'
  },
  arrowDiv: {
    height: 26,
    width: 26,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modelSubpopper: {
    maxHeight: '271px',
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
    background: "#FFF",
    borderBottom: 'none',
    boxShadow: 'none !important',
    padding: '0px !important',
  },
  autoComplete: {
    borderRadius: '8px',
    border: "1px solid #D9D9D9",
    padding: '11px',
    boxShadow: 'none',
    backgroundColor: "#FAFAFA"
  },
  autoErrorComplete: {
    borderRadius: '8px',
    border: "1px solid #f44336",
    padding: '11px',
    boxShadow: 'none'
  },
  errorMessage: {
    color: '#f44336',
    padding: '1px',
    fontSize: '14px'
  },
  optionName: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    fontSize: '14px'
  },
  container: {
    paddingLeft: "44px",
    width: "75%",
    display: "flex",
    gap: 20,
    marginTop: '30px',
    "@media(max-width: 400px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 10,
      width: '100%'
    },
  },
  modelpopper: {
    maxHeight: '271px',
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
    background: "#FFF",
    borderBottom: 'none',
    boxShadow: 'none !important',
    padding: '0px !important',
  },
  datetimeinput: {
    width: '100%',
    display: 'flex',
    padding: '13px 10px 8px',
    alignItems: 'center',
    alignSelf: 'stretch',
    border: "#00000024 solid 1px",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "#FAFAFA"
  },
  datetimeinputError: {
    width: '100%',
    display: 'flex',
    padding: '13px 10px 8px',
    alignItems: 'center',
    alignSelf: 'stretch',
    border: "#f44336 solid 1px",
    borderRadius: "8px",
    cursor: "pointer",
    color: '#f44336'
  },
  activityLabel: {
    fontSize: '16px',
    lineHeight: '25.6px',
    fontWeight: 700,
    fontFamily: "Lato",
    color: '#0F172A',
  },
  commentBtnContainer: {
    width: '97px',
    height: '40px',
    background: '#43D270',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    display: 'flex',
  },
  commentBtnText: {
    fontSize: '12px',
    lineHeight: '19px',
    textAlign: 'center',
    fontFamily: "Inter",
    fontWeight: 'bold'
  } as CSSProperties,
  commentMessage: {
    fontSize: '12px',
    lineHeight: '19.2px',
    fontWeight: 500,
    fontFamily: 'Lato',
    color: '#7D7D7F',
  } as CSSProperties,
  commentTitleText: {
    fontSize: '14px',
    lineHeight: '22.4px',
    fontWeight: 700,
    fontFamily: 'Lato',
    color: '#34373A',
    marginRight: '16px',
  } as CSSProperties,
  dropdownContainer: {
    minWidth: '157px',
    height: '34px',
    background: "#43D270",
    borderRadius: '8px',
    fontFamily: 'Lato !important',
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#34373A",
  },
  editBtnContainer: {
    display: "flex",
    flexDirection: 'row',
    gap: 10,
    width: '73px',
    height: '38px',
    border: '1px solid #DBDADF',
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  } as CSSProperties,
  editBtnText: {
    fontFamily: 'Lato !important',
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22.4px",
    color: "#7D7D7F",
  },
  saveBtnContainer: {
    display: 'flex',
    width: '97px',
    height: '40px',
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#43D270',
    cursor: 'pointer'
  } as CSSProperties,
  saveBtnText: {
    fontSize: '14px',
    lineHeight: '19.2px',
    fontWeight: 700,
    fontFamily: 'Lato',
    color: '#34373A',
  } as CSSProperties,
  modalWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  completedModalPaper: {
    width: '100%',
    maxWidth: "455px",
    padding: '40px 30px 20px',
    height: '241px',
    borderRadius: '12px',
    overflow: 'scroll' as 'scroll',
    scrollbarWidth: 'none' as 'none',
    msOverflowStyle: 'none' as 'none',
    textAlign: 'center' as 'center'
  },
  sucsessTitle: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '30px',
    color: '#0F172A',
    textAlign: 'center' as 'center',
    margin: '30px 0 10px'
  },
  successMessage: {
    fontSize: '17px',
    lineHeight: '26px',
    color: '#34373A',
    textAlign: 'center' as 'center'
  }
}
// Customizable Area End