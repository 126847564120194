Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.updateAPiMethod = "PUT";
exports.deleteAPiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "GanttChart";
exports.labelBodyText = "GanttChart Body";
exports.quarterOfDayButtonTitle = "Quarter of Day";
exports.halfDayButtonTitle = "Half of Day";
exports.dayButtonTitle = "Day";
exports.weekButtonTitle = "Week";
exports.monthButtonTitle = "Month";
exports.yearButtonTitle = "Year";
exports.checkboxTitle = "Show Task List";

exports.btnExampleTitle = "CLICK ME";
exports.getGanttChartData = "/bx_block_certification/swim_lane_view";
exports.getGanttChartWaterfallData = "bx_block_gantt_chart/water_fall";
exports.addGanttChartData = "bx_block_gantt_chart/gantt_charts";
exports.editGanttChartData = "bx_block_gantt_chart/gantt_charts";
exports.actionPlanText = "Action Plan";
exports.actionPlanDescription = "Copy that talks about what time means to the users and some guidance or instructions or what to make from this and how to proceed forward. This  talks about what time means to the users and some guidance or instructions or what to make from this and how to proceed forward."
// Customizable Area End
