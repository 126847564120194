Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "GoalManagement";
exports.labelBodyText = "GoalManagement Body";

exports.btnExampleTitle = "CLICK ME";
exports.noTask = "No task’s has been created yet";
exports.createButton = "+ Create Task";
exports.CreateTask = "Create Task";
exports.createTaskDescription = "Quickly add a new task to your project. Set a title, due date, and priority to stay on track.";
exports.getCategoryEndpoint = "bx_block_goalmanagement/get_climatic_goals";
exports.taskNamePlaceholder = "Enter Task Name";
exports.taskName = "Task Name";
exports.selectSubCategory = "Select Sub Category";
exports.SelectAssignee = "Select Assignee";
exports.StartDate = "Start Date";
exports.EndDate = "End Date";
exports.SelectCriticality = "Select Criticality";
exports.Description = "Description";
exports.descriptionPlaceholder = "Enter description";
exports.getAssigneeApiEndpoint = "bx_block_goalmanagement/load_task_datas";
exports.errorMessage = "Please fill the field to proceed";
exports.createTaskEndPoint = "bx_block_goalmanagement/save_task";
exports.addMoreButton = "+ Add More Task";
exports.deleteTaskEndpPoint = "bx_block_goalmanagement/delete_task";
exports.deleteApiMethod = "DELETE";
exports.deleteConfirmationMessage = "Are you sure you want to delete this task?";
exports.noButton = "No";
exports.yesButton = "Yes";
exports.saveProceed = "Save & Proceed";
exports.toastMessage = "To proceed to the next category, you must create a tasks with at least two subcategories.";
exports.completeTaskEndPoint = "bx_block_goalmanagement/save_complete_task";
exports.back = "Back";
exports.complete = "Complete";
exports.completeConfirmationMessage = "Are you sure you want to Complete this task?";
exports.successMessage = "Your task has now been successfully created";
exports.successTitle = "Success!";
exports.actionPlan = "Action Plan";
exports.actionDescription = "Copy that talks about what time means to the users and some guidance or instructions or what to make from this and how to proceed forward. This  talks about what time means to the users and some guidance or instructions or what to make from this and how to proceed forward.";
exports.taskNameHeader = "Task Name";
exports.subCategoryHeader = "Sub Category";
exports.assigneeHeader = "Assignee";
exports.criticalityHeader = "Criticality";
exports.startDate = "Start Date";
exports.EndDate = "End Date";
exports.getEditTaskEndPoint = "bx_block_goalmanagement/edit_task";
exports.updateTaskEndPoint = "bx_block_goalmanagement/update_task/update_task";
exports.updateMethod = "PATCH";
exports.commentEndPoint = "bx_block_goalmanagement/task_comment";
exports.updateTaskStatusEndPoint = "bx_block_goalmanagement/update_task/update_task_status";
// Customizable Area End