import React from "react";
import "gantt-task-react/dist/index.css";

import {
  // Customizable Area Start
  // Customizable Area End
  Box,
} from "@mui/material";
// Customizable Area Start
import {
  FormControl,
  Typography,
  MenuItem,
  Select,
} from "@material-ui/core"
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  styled,
} from "@mui/material/styles";
import "@mui/styles";
import Layout from "../../dashboard/src/Layout.web";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Gantt } from "gantt-task-react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import GanttChartController, {
  configJSON,
  Props,
} from "./GanttChartController";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class GanttChart extends GanttChartController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Layout>
            <Box style={{ marginLeft: '44px', marginTop: '32px', marginRight: '44px' }}>
              <Box style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                <Typography style={webStyle.actionText}>{configJSON.actionPlanText}</Typography>
                <AnalizeDropdown style={{border:'1px solid #DFDFDF'}}>
                  <AnalizeSelectField
                    displayEmpty
                    data-test-id="duration-filter"
                    IconComponent={() => (
                      <>
                        {this.state.openDropdown ?
                          <ExpandLessIcon onClick={this.toggleDropDown} style={webStyle.arrowUpIcon} />
                          :
                          <ExpandMoreIcon onClick={this.toggleDropDown} style={webStyle.arrowUpIcon} />
                        }
                      </>
                    )}
                    open={this.state.openDropdown}
                    onClose={() => this.closeDropDown()}
                    onOpen={() => this.handleOpenDropDown()}
                    value={this.state.selectedPeriodType}
                    onChange={this.handleChangeEmmission}
                    disableUnderline
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          width: "100px",
                          maxWidth: "100%",
                        },
                      },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 395,
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 400,
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {
                      ['Monthly', 'Quarterly', 'Half Yearly'].map((year) => (
                        <MenuItem style={webStyle.menuItems} value={year}>{year}</MenuItem>
                      ))
                    }

                  </AnalizeSelectField>
                </AnalizeDropdown>
              </Box>
              <Typography style={webStyle.actionDescription}>{configJSON.actionPlanDescription}</Typography>
            </Box>
            <Box style={{ marginLeft: '44px', marginRight: "44px", marginTop:'33px' }}>
              <Gantt
                tasks={this.formatTasks()}
                viewMode={this.state.ganttChartView}
                listCellWidth={this.state.isChecked ? "155px" : ""}
                columnWidth={this.state.ganttChartColumnWidth}
              />
            </Box>

          </Layout>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  actionText: {
    fontSize: '22px',
    fontWeight: 700,
    color: '#34373A',
    lineHeight: '27.5px'
  },
  actionDescription: {
    fontSize: '12px',
    color: '#7D7D7F',
    lineHeight: '19.2px',
    maxWidth: '75%',
    marginBottom: '33px'
  },
  menuItems: {
    maxWidth: '92px !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: "1px !important",
    fontSize: '10px'
  } as React.CSSProperties,
  arrowUpIcon: {
    cursor: 'pointer',
    fontSize: '20px',
    height: '20px',
    color: '#000'
  },
};
const AnalizeDropdown = styled(FormControl)({
  borderRadius: '8px',
  width: "100%",
  maxWidth: "100px !important",
  border: '1px solid rgba(0, 0, 0, 0.2)',
})

const AnalizeSelectField = styled(Select)({
  height: "36px",
  borderRadius: "8px",
  padding: "8px !important;",
  "& *": {
      background: "#fff !important;",
      fontFamily: "Lato",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "19.2px",
      color: '#34373A',
      paddingRight: '0px !important'
  },
  '&:focus': {
      borderColor: '#cccccc',
  },
  '& .MuiInputBase-input-209':{
    color:'#34373A',
    fontSize:'12px',
    lineHeight: '19.2px',
    fontFamily:'Lato',
    fontWeight:600,
  }
})
// Customizable Area End
